/* section one css */

.car-cards {
    width: 300px;
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensures the card takes up full height of the parent container */
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
    flex-shrink: 0; /* Ensures the header doesn't shrink */
    overflow: hidden;
    border-bottom: 1px solid #ddd;
    height: 200px; /* Adjust based on your needs */
}

.card-header img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the header */
}

.card-body {
    flex-grow: 1; /* Makes the body take up remaining space */
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.card-body h4 {
    margin-bottom: 15px;
    font-size: 1.25rem;
    font-weight: bold;
}

.card-footer {
    flex-shrink: 0; /* Ensures the footer doesn't shrink */
    display: flex;
    justify-content: space-between;
    background-color: #f9f9f9;
    padding: 10px;
    border-top: 1px solid #ddd;
}

.footer-box {
    display: flex;
    width: 115px !important;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.footer-box-icon {
    width: 40px;
    height: 30px;
}

.footer-box.primary_background_color {
    background-color: #007bff;
}

.footer-box.background-green {
    background-color: #28a745;
}

.footer-box.background-orange {
    background-color: #fd7e14;
}

.footer-box:hover {
    opacity: 0.8; /* Adds a hover effect */
}

.home-section-one {
    height: 55vh;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* overflow: hidden; */
    /* background: url(../assets/backgrounds/home.webp); */
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -100px;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5)), linear-gradient(to top, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5)), url(../assets/backgrounds/home-bg.webp); */
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5)), linear-gradient(to top, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5)), url(../../app/assets/backgrounds/home-main.jpeg);
    background-position: center center;
}
.links {
    color:#3f71b9 ;
}
.primary_background_color {
    background-color: #3f71b9 !important;
} 
.primary_background_color:hover {
    background-color: #3f71b9;
    text-decoration: underline;
} 
.links:hover {
    text-decoration: underline;
}
.sub-section {
    /* background-color: lightblue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.search-container {
    background-color: rgba(34, 34, 34, 0.6);
    display: flex;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.search-input {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    border-radius: 10px;
}

.search-icon {
    background-image: url(../../app/assets/icons/search.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 35px;
    width: 35px;
    margin-right: 20px;
}

.search-input input {
    border: none;
    outline: none;
    width: 350px;
}

.home_btn {
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 15px; */
    padding: 10px;
}

.view-all-cars-btn {
    width: 150px;
}

.btn-icon {
    background-image: url(../../app/assets/icons/next.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 15px;
    width: 15px;
    margin-left: 5px;
}

.search-results-container {
    background-color: white;
    /* width: 600px; */
    padding: 10px;
    height: 350px;
    overflow: auto;
    position: absolute;
    /* z-index: 9999; */
    top: 70px;
    left: 3px;
    border-radius: 10px;
    overflow-y: scroll;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}


/* width */
.search-results-container::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.search-results-container::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 10px;
    background-color: white;
}

/* Handle */
.search-results-container::-webkit-scrollbar-thumb {
    background: lightgrey;
    border-radius: 5px;
}

/* Handle on hover */
.search-results-container::-webkit-scrollbar-thumb:hover {
    background: #3f71b9;
}

.search-result-item {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    color: black;
}

.search-result-item:hover {
    background-color: whitesmoke;
    cursor: pointer;
    color: #3f71b9;
}


/* section two css */
.home-section-two {
    /* z-index: auto; */
    /* background-color: pink; */
    padding: 30px 0px;
}

.section-two-header {
    /* background-color: lime; */
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    padding: 0px 40px;
    margin-bottom: 20px;
    align-items: center;
}

.section-two-btn {
    border: 1px solid #3f71b9 !important;
    border-radius: 7px;
    width: 300px;
}

.cards-container {
    /* background-color: pink; */
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

/* .cards {
    padding: 20px;
} */

.car-card {
    background-color: white;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    /* transition: all 0.5s ease-out; */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    padding-bottom: 10px;
    font-size: 17px;
}

.car-card:hover {
    background-color: lightgrey;
    color: #3f71b9;
    /* transform: scale(1.1); */
}

.car-card-img {
    height: 126px;
    width: 222px;
}

.text-container {
    margin-top: 50px;
    padding: 0px 50px;
}

.para {
    cursor: pointer;
    text-align: justify;
}

/* section three css */
.home-section-three {
    /* background-color: pink; */
    padding: 30px 50px 50px 50px;
}

.section-three-header {
    /* background-color: lime; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px dashed gray;
    padding-bottom: 20px;
    align-items: center;
    margin-bottom: 20px;
}

.brands-cards-container {
    /* background-color: lime; */
    margin-top: 30px;
}

.brand-card {
    /* background-color: pink; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
}

.logo-wrapper {
    width: 100px;
    height: 100px;
    display: flex;
    width: 100px;
    justify-content: center;
    height: 100px;
    align-items: center;
}
.brand-img {
    aspect-ratio: 2/1;
    object-fit: contain;
    /* mix-blend-mode: color-burn; */
}
.logo-wrapper:hover {
    background-color: orange;
    border-radius: 50%;
}

.list-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

/* section four css */
.home-section-four {
    /* background-color: pink; */
    padding: 30px 50px 50px 50px;
}

.section-four-header {
    /* background-color: lime; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px dashed gray;
    padding-bottom: 20px;
    align-items: center;
    margin-bottom: 20px;
}

.car-cards-container {
    margin-top: 50px;
}

.car-cards {
    background-color: white;
    border-radius: 10px;
    width: 350px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
    cursor: pointer;
    /* transition: all 0.5s ease-out; */
    transform: scale(0.9);
    /* margin-left: 12px; */
    border: 1px solid lightgrey;
    /* margin-left: -18px; */
}

.car-cards-slider {
    transform: scale(0.8);
}
.redirect-page:hover {
    color: orange;
    /* text-decoration: underline; */
} 

.car-cards:hover {
    /* transform: scale(1); */
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    border: 1px solid orange;
}

.card-header {
    position: relative;
}

.card-header-img {
    height: 200px;
    width: 100%;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.car-card-badge-container {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    gap: 5px;
}

.car-card-badge {
    font-size: 13px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 5px;
    font-weight: 100 !important;
}

.card-body {
    padding: 20px 10px 10px 10px;
}

.test {
    border: 1px solid red  !important;
}
.card-details {
    /* background-color: pink; */
    display: flex;
    margin: 10px 0px 10px 0px;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
}

.details-one {
    /* background-color: lime; */
    width: 50%;
    display: flex;
    flex-direction: column;
}

.details-two {
    /* background-color: yellow; */
    width: 50%;
    display: flex;
    flex-direction: column;
}

.specs {
    /* background-color: pink; */
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
}

.small-spec {
    /* background-color: lightblue; */
    width: 50px;
    margin: 5px;
    padding: 3px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
}

.medium-spec {
    /* background-color: yellow; */
    width: 100px;
    margin: 5px;
    padding: 3px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
}

.large-spec {
    /* background-color: yellow; */
    width: 170px;
    margin: 5px;
    padding: 3px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
}

.spec-icon {
    height: 17px;
    width: 17px;
    margin-right: 5px;
}

.card-footer {
    /* background-color: pink; */
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dealer-logo {
    height: 50px;
    /* width: 70px; */
    /* width: 60px; */
}

.card-footer-dealer-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
}

.card-footer-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 25px;
    /* background-color: pink; */
    width: 60%;
}

.footer-info {
    display: flex;
    margin-bottom: 5px;
}

.footer-icons {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.check-icon {
    width: 16px;
    height: 16px;
    margin-left: 2px;
}

.card-footer-text {
    font-size: 13px;
}

.card-buttons {
    /* background-color: red; */
    display: flex;
    padding: 3px;
    justify-content: space-between;
    margin-top: 5px;
}

.card-btn {
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    /* transition: all 0.5s ease-out; */
}

/* .card-btn:hover {
    transform: scale(1.1);
} */

.card-btn-icons {
    height: 20px;
    width: 20px;
}

.phone-btn {
    border-bottom-left-radius: 10px;
}

.send-btn {
    border-bottom-right-radius: 10px;
}

/* section five css */
.home-section-five {
    /* background-color: pink; */
    padding: 30px 50px 50px 50px;
}

.section-five-header {
    /* background-color: lime; */
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px dashed gray;
    padding-bottom: 10px;
    align-items: center;
    margin-bottom: 20px;
}

.cities-cards-container {
    /* background-color: red; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.city-card {
    /* background-color: pink;   */
    width: 350px;
    margin: 10px 0px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    border-radius: 15px;
    /* transition: all 0.5s ease-out; */
}

.city-card:hover {
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* transform: scale(1.1); */
    transform: scale(1.02);
}

.city-card-img {
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
    background-position: center center;
}

.city-card-img .city-name {
    color: white;
    position: absolute;
    left: 25px;
    bottom: 25px;
    font-size: 20px;
    font-weight: bold;
}

.city-card-img .cars-details {
    color: white;
    position: absolute;
    left: 25px;
    bottom: 0px;
    font-size: 17px;
}



/* section six css */
.home-section-six {
    /* background-color: pink; */
    padding: 0px 50px 50px 50px;
}

.section-six-header {
    /* background-color: lime; */
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px dashed gray;
    padding-bottom: 10px;
    align-items: center;
    margin-bottom: 20px;
}

.services-cards-container {
    /* background-color: red; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 20px 0px 0px 0px;
}

.service-card {
    /* background-color: pink;   */
    width: 350px;
    margin: 10px 0px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    border-radius: 15px;
    /* transition: all 0.5s ease-out; */
}

.service-card:hover {
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* transform: scale(1.1); */
    transform: scale(1.02);
}

.service-card-img {
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
}

.service-card-img .service-name {
    color: white;
    position: absolute;
    left: 20px;
    bottom: 5px;
    font-size: 18px;
}

.sci-one {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1)), url(../../app/assets/home/DubyDrive_Car_Rentals_available_across_UAE.jpg);
}

.sci-two {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1)), url(../../app/assets/home/DubyDrive_Book_Desert_Safari_Tour.webp);
}

.sci-three {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1)), url(../../app/assets/home/Duby_Drive_Yacht_Rentals_in_UAE.webp);
}

.sci-four {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1)), url(../../app/assets/home/DubyDrive_Yacht_Rentals_in_UAE.webp);
}


/* section seven css */
.home-section-seven {
    /* background-color: pink; */
    margin: 30px 0px 0px 0px;
    background-image: url(../../app/assets/backgrounds/testimonital-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 50px;
    background-position: center center;
}

.section-seven-header {
    /* background-color: lime; */
    display: flex;
    justify-content: center;
    border-bottom: 1px dashed gray;
    padding-bottom: 10px;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
}

.section-seven-heading {
    color: white;
}

.testimonials-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: pink; */
    margin-top: 10px;
}

.profile-icon {
    height: 70px;
    width: 70px;
    margin-bottom: 10px;
    border-radius: 50%;
}

.user-name {
    color: white;
    margin-bottom: 1px;
}

.testimonial-date {
    color: white;
}

.reviews {
    display: flex;
    /* background-color: lightblue; */
    margin-bottom: 20px;
}

.star-icon {
    height: 35px;
    width: 35px;
    margin: 0px 5px;
}

.read-all-reviews-link {
    color: orange;
    text-decoration: none;
    margin-bottom: 20px;
}

.right-angle-icon {
    height: 17px;
    width: 17px;
    margin-right: -10px;
}

.reviews-container {
    /* background-color: red; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 30px;
    gap: 30px;
}

/* .ratings {
    background-color: pink;  
} */

.rating-icon {
    height: 30px;
    width: 30px;
    margin: 0px 5px;
}

.reviews-details {
    /* background-color: lime; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.reviews-details p {
    font-size: 25px;
    text-align: center;
}

/* section eight css */
.home-section-eight {
    /* background-color: pink; */
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    padding: 30px 50px;
}

.section-eight-sub-one {
    /* background-color: lightblue; */
    padding: 20px;
}

.section-eight-header {
    /* background-color: purple; */
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px dashed gray;
    padding-bottom: 5px;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.section-eight-sub-two {
    /* background-color: lime; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.map-img {
    /* background-color: yellow; */
    background-image: url(../../app/assets/home/DubyDrive_UAE_Map.webp);
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 50%;
}

/* section nine css */
/* .home-section-nine {
    background-color: pink;
} */

.middle-section {
    padding: 20px 50px;
}

.section-nine-header {
    /* background-color: purple; */
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px dashed white;
    padding-bottom: 5px;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.box-container {
    padding: 10px;
    /* background-color: yellow; */
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
}

.box {
    /* background-color: lime; */
    width: 470px;
}

.box-header {
    /* background-color: pink; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px;
    margin-bottom: 20px;
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 5px;
    border-radius: 50%;
}

.box-icon {
    height: 30px;
    width: 30px;
}

/* section ten css */
.home-section-ten {
    /* background-color: pink; */
    padding: 30px 50px 50px 50px;
    margin-top: 30px;
    border-top: 2px solid #3f71b9;
}

.section-ten-header {
    /* background-color: purple; */
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px dashed gray;
    padding-bottom: 5px;
    align-items: center;
    margin-bottom: 10px;
}

.section-ten-mid-section {
    /* background-color: lightblue; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
}

.card-table {
    /* background-color: yellow; */
    width: 550px;
    border: 1px solid lightgrey;
    margin-bottom: 10px;
}

.card-table-header {
    background-color: lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.card-table-body {
    /* background-color: lime; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
}

.card-table-img-container {
    display: flex;
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-table-car-img {
    height: 75px;
    width: 150px;
}

.card-table-details-container {
    /* background-color: purple    ; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 15px 20px 30px;
}

.card-table-detail {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
}

.card-table-icon {
    height: 20px;
    width: 20px;
    margin-right: 7px;
}

.section-ten-footer {
    /* background-color: purple; */
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px dashed gray;
    margin-top: 30px;
    align-items: center;
    margin-bottom: 10px;
}

/* section eleven css */
.home-section-eleven {
    /* background-color: pink; */
    padding: 50px 100px 5px 100px;
}

.content-container {
    margin-bottom: 50px;
}

.section-eleven-content-header {
    /* background-color: purple; */
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px dashed white;
    padding-bottom: 5px;
    align-items: center;
    margin-bottom: 10px;
}

/* section twelve css */
.home-section-twelve {
    /* background-color: pink; */
    padding: 50px 100px 50px 100px;
}

.section-twelve-footer {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
}

.section-twelve-footer-text {
    font-style: italic;
}

.brand-img {
    transition: transform 0.3s ease-in-out;
}

.brand-card:hover .brand-img {
    transform: scale(1.1); /* Scales the image up slightly */
}

.brand-card:hover .brands-cards-slider-text {
    color: #FF5733; /* Change this color to your desired hover color */
    transition: color 0.3s ease-in-out;
}



/* section thirteen css */
.home-section-thirteen {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1)), linear-gradient(to top, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1)), url(../../app/assets/home/Join_DubyDrive.webp);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
    background-position: center center;
}

@media screen and (max-width:600px) {
    .home-section-eight {
        padding: 10px;
    }

    .home-section-eleven {
        padding: 15px;
    }

    .home-section-twelve {
        padding: 20px;
    }
}

@media screen and (max-width:767px) {
    .search-results-container {
        width: 100%;
        margin-top: -9px;
    }

    .map-img {
        display: none;
    }
}

@media screen and (max-width:920px) {
    .car-card {
        /* background-color: yellow; */
        width: 250px;
    }

    .car-card-img {
        height: 80px;
        width: 160px;
    }

    /* .brand-card {
        background-color: red;
    } */

    .brand-img {
        height: 50px;
        width: 50px;
    }

    .brands-cards-slider-text {
        font-size: 13px;
    }
}

@media screen and (max-width:815px) {

    .city-card {
        /* background-color: pink;   */
        width: 300px;
    }

    .city-card-img {
        height: 170px;
    }

    .service-card {
        width: 300px;
    }

    .service-card-img {
        height: 170px;
    }
}

@media screen and (max-width:769px) {
    .car-card {
        width: 200px;
    }

    .car-card-img {
        height: 50px;
        width: 100px;
    }
}

@media screen and (max-width:713px) {

    .city-card {
        width: 250px;
    }

    .city-card-img {
        height: 150px;
    }

    .city-card-img .city-name {
        font-size: 20px;
        font-weight: bold;
        bottom: 0px;
    }

    .city-card-img .cars-details {
        display: none;
    }

    .service-card {
        width: 250px;
    }

    .service-card-img {
        height: 150px;
    }

    .service-card-img .service-name {
        font-size: 12px;
        bottom: 0px;
    }

}

@media screen and (max-width:619px) {
    .car-card {
        width: 150px;
        align-items: normal;
    }

    .car-card-img {
        height: 50px;
        width: 100px;
    }

    .home-section-five {
        padding: 30px 10px 50px 10px;
    }

    .home-section-six {
        padding: 0px 10px 150px 10px;
    }


    .city-card {
        width: 200px;
    }

    .city-card-img {
        height: 130px;
    }

    .city-card-img .city-name {
        font-size: 18px;
        font-weight: bold;
        bottom: -5px;
    }

    .service-card {
        width: 200px;
    }

    .service-card-img {
        height: 130px;
    }

    .service-card-img .service-name {
        left: 10px;
        bottom: -5px;
        font-size: 10px;
    }

}

@media screen and (max-width:516px) {

    .city-card {
        width: 150px;
    }

    .city-card-img {
        height: 100px;
    }

    .city-card-img .city-name {
        font-size: 15px;
        font-weight: bold;
        bottom: -10px;
        left: 10px;
    }

    .service-card {
        width: 150px;
    }

    .service-card-img {
        height: 100px;
    }

    .service-card-img .service-name {
        left: 10px;
        bottom: -5px;
        font-size: 8px;
    }
}

@media screen and (max-width:470px) {
    .car-card {
        /* background-color: greenyellow; */
        width: 120px;
    }

    .home-section-two-text {
        font-size: 14px;
    }

    .section-two-header {
        /* background-color: lightblue; */
        padding: 0px 15px;
    }

    .section-two-main-heading {
        /* color: blue; */
        font-size: 18px;
    }
}

@media screen and (max-width:379px) {
    .car-card {
        width: 100px;
    }
}

@media screen and (max-width:680px) {
    .search-input input {
        width: 300px;
    }

    .home-section-thirteen {
        padding: 10px;
    }

    .view-all-brands-btn {
        display: none;
    }

    .view-all-brands-icon-btn {
        visibility: visible !important;
    }

    .section-three-main-heading {
        font-size: 18px;
    }

    .home-section-three {
        padding: 10px;
    }

    .home-section-four {
        padding: 10px;
    }

    .text-container {
        padding: 10px;
    }
}

@media screen and (max-width:560px) {
    .search-icon {
        height: 35px;
        width: 35px;
        margin-right: 10px;
    }

    .search-input input {
        width: 250px;
    }

    .search-container {
        transform: scale(0.9);
    }

    .home-main-text {
        transform: scale(0.9);
    }
}

@media screen and (max-width:445px) {
    .search-container {
        transform: scale(0.8);
        margin-top: -15px;
    }

    .home-main-text {
        transform: scale(0.8);
    }
}

@media screen and (max-width:395px) {
    .search-container {
        transform: scale(0.7);
        margin-top: -25px;
    }

    .home-main-text {
        transform: scale(0.7);
    }
}

@media screen and (max-width:280px) {
    .search-container {
        transform: scale(0.5);
        margin-top: -35px;
    }

    .home-main-text {
        transform: scale(0.5);
    }

    .home-main-heading {
        margin-bottom: -15px;
    }
}